<template>
  <div class="demo">
    <van-tabs v-model="activeHome" sticky>
  <van-tab title="回收类别">
<van-grid :border="true" :column-num="3"  square>
  <van-grid-item  v-for='(item,key) in todayHuishou' @click='showItemChildren(key)'>
    <van-image :src="item.path" width="50" height="50" />
    <span style="font-size: 14px">{{item.title}}</span>
  </van-grid-item>
</van-grid>
  </van-tab>
  <van-tab title="预约回收">
  <div class="demo">
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
    <van-tabs v-model="activeName" @click="onClick" color='#07C17A'>
      <van-tab name="tab1" title="创建预约">
        <van-cell-group>
          <van-field
            v-model="save_data.desc"
            rows="2"
            autosize
            label="物品描述"
            type="textarea"
            maxlength="200"
            placeholder="简单描述下物品，长度5~200字符"
            show-word-limit
          />
          <van-field
            v-model="save_data.user_weight"
            label="预估重量"
            type="number"
            placeholder="请填写物品预估重量"
          >
          <span slot="button">千克</span>
          </van-field>
          <van-popup v-model="showDate" position="bottom">
            <van-datetime-picker
              v-model="currentDate"
              type="datetime"
              :min-date="minDate"
              @cancel="showDate = false"
              @confirm="onDateConfirm"
              :formatter="formatter"
            />
          </van-popup>
          <van-cell is-link title="取件地址" value="选择地址" @click="showAddress=true">
            <template #label>
              <div v-if="addressNow" style="height: 40px;line-height: 20px;">
                <span style="color: #666;position: absolute;">{{addressNow.name}}-{{addressNow.phone}}<br/>{{addressNow.ssq}}{{addressNow.address}}</span>
              </div>
            </template>
          </van-cell>
          <van-field
            readonly
            v-model="save_data.visit_time"
            label="上门时间"
            placeholder="请选择上门时间"
            right-icon="arrow"
            @click="showDate = true"
          >
          </van-field>
          <van-field
            v-model="save_data.phone"
            label="联系电话"
            type="number"
            placeholder="请填写电话"
          >
          </van-field>
          
          <van-field
            readonly
            label="物品图片"
            :placeholder="'最多上传'+maxCount+'张'"
          />
          <div class="upimg">
          <div class="img_list" v-for="(img,idx) in this.save_data.imgs">
            <van-image
              class="imgs"
              fit="contain"
              :src="img.url"
            />
            <van-icon class="delimg" @click="delImg(idx)" name="cross" />
          </div>
          <van-uploader :after-read="afterRead" v-model="files" v-if="save_data.imgs.length<maxCount" :preview-image="false" :before-read="beforeRead"/>
          <div style="clear: both;height: 1px;"></div>
          </div>
        </van-cell-group>

        <div style="text-align:center;margin-top: 10px;padding: 0 15px;">
          <van-button type="primary" size="large" round @click="onSubmit">提交订单</van-button>
        </div>

        <van-popup v-model="showAddress" position="bottom">
          <van-cell-group>
            <van-cell
            v-for="(item,idx) in addressList"
            :title="item.name"
            @click="onSelect(item)"
            >
            <template slot="title">
              <span class="custom-title">{{item.name}}</span>
              <van-tag type="danger" v-if="item.is_def">默认</van-tag>
            </template>
            <template slot="label">
              {{item.phone}}<br/>{{item.ssq}}-{{item.address}}
            </template>
              <van-tag slot="right-icon" style="height:16px;" type="default">选择</van-tag>
            </van-cell>
          </van-cell-group>
          <p style="text-align:center"><van-button type="default" @click="onAdd" round>新增地址</van-button></p>
        </van-popup>

      </van-tab>
      <van-tab name="tab2" title="最新预约">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoadNew"
        >
        <!-- 状态：1=待接收，2=已接收 3=已取货 4=已交货给分拣人员，5=已拒绝取消 -->
          <van-panel v-for="item in list" :title="'#'+item.order_no" :status="item.status_txt" style="margin-bottom:20px!important;">
            <div class="orderCon">
              <div>物品：{{item.desc}}</div>
              <div>重量：{{item.user_weight}}Kg</div>
              <div>地址：{{item.address}}</div>
              <div>上门时间：{{item.visit_time}}</div>
              <div>提交时间：{{item.create_time}}</div>
              <div v-if="item.cancel_msg">原因：{{item.cancel_msg}}（{{item.cancel_time}}）</div>
            </div>
          </van-panel>
        </van-list>
      </van-tab>
      <van-tab  name="tab3" title="历史预约">
        <van-list
          v-model="loading"
          :finished="old_finished"
          finished-text="没有更多了"
          @load="onLoadOld"
        >
        <!-- 状态：1=待接收，2=已接收 3=已取货 4=已交货给分拣人员，5=已拒绝取消 -->
          <van-panel v-for="item in old_list" :title="'#'+item.oy_id" :status="item.status_txt" style="margin-bottom:20px!important;">
            <div class="orderCon">
              <div>物品：{{item.type}}</div>
              <div>重量：{{item.user_weight}}Kg</div>
              <div>地址：{{item.address_detail}}</div>
              <div>上门时间：{{item.visitime}}</div>
              <div>提交时间：{{item.addtime}}</div>
              <div v-if="item.status==5">原因：{{item.cancel_msg}}（{{item.o_time5}}）</div>
            </div>
          </van-panel>
        </van-list>
      </van-tab>
    </van-tabs>
    </van-pull-refresh>
  </div>
  </van-tab>
</van-tabs>
<van-popup v-model="show" round position="bottom" style="max-height: 60%">
    <van-cell v-for='(i,k) in currentItem' @click='jumpToYuyue(k)'>
      <div class="flex space-between">
        <van-image width="60" :src="i.path" />
        <span>{{i.title}}</span>
      </div>
    </van-cell>
</van-popup>
</div>
</template>

<script>
import * as ajax from "@/api/common"
import util from '@/libs/util'
export default {
  name: 'YuyuePage',
  data(){
    return {
      content:null,
      show:false,
      todayHuishou:[],
      activeHome:0,
      save_data:{
        type:[],
        user_weight:'',
        phone:'',
        visit_time:'',
        address:'',
        area_code:'',
        imgs:[],
        desc:''
      },
      addressNow:false,
      isLoading:false,
      files:[],
      addressList:[],
      maxCount:3,
      minHour: 9,
      maxHour: 20,
      minDate: new Date(),
      maxDate: new Date(2030, 10, 1),
      currentDate: new Date(),
      showType:false,
      showDate:false,
      type_arr:['纸板','塑料','废旧金属','其他'],
      activeName:'tab1',
      list:[],
      old_list:[],
      loading:false,
      finished:false,
      old_finished:false,
      activeNames:1,
      userInfo:false,
      isLoading:false,
      page:0,
      old_page:0,
      page_size:10,
      showAddress:false,
      result:[],
      currentItem:[]
    }
  },
  mounted () {
    this.active = this.$route.query.active?parseInt(this.$route.query.active):0
    this.getAddress()
    this.getPriceInfoToday()
  },
  watch:{
    $route(to,from){
      this.active=to.query.active
    },
  },
  methods:{
    jumpToYuyue(index)
    {
      this.activeHome = 1;
      this.save_data.desc = this.currentItem[index].title
      this.show = false
    },
    showItemChildren(index){
      this.show = true
      this.currentItem = this.todayHuishou[index].child
    },
    getPriceInfoToday(){
      // ajax.get('/base/getPriceInfoToday',{}).then(res=>{
      //   this.loading=false
      //   this.todayHuishou = res.list
      // }).catch(e=>{
      //   console.log(e)
      // })
      ajax.post('/book/getDetailByPriceId',{price_id:143}).then(res=>{
        this.loading=false
        this.todayHuishou = res
      }).catch(e=>{
        console.log(e)
      })            
    },
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
    },
    onSelect(val){
      this.showAddress=false
      this.addressNow=val
      this.save_data.phone=val.phone
      this.save_data.area_code=val.code
      this.save_data.address=val.address
    },
    onAdd(){
      //保存当前页面已填写的内容
      util.cookies.set('yuyueData',this.save_data)
      this.$router.push({
        path:'/my/addressList',
        query:{
          action:'from_yuyue'
        }
      })
    },
    // 返回布尔值
    beforeRead(file) {
      if (file.type !== 'image/jpeg' || file.type !== 'image/png') {
        this.$toast('请上传 jpg/png 格式图片');
        return false;
      }
      return true;
    },
    // 返回 Promise
    asyncBeforeRead(file) {
      return new Promise((resolve, reject) => {
        if (file.type !== 'image/jpeg' || file.type !== 'image/png') {
          this.$toast('请上传 jpg/png 格式图片');
          reject();
        } else {
          resolve();
        }
      });
    },
    getAddress(){
      let def = util.cookies.get('yuyueData')
      if(def){
        this.save_data=JSON.parse(def)
      }
      ajax.get('/Base/getYuYuePrepare',{table:'Address'}).then(async res => {
        this.addressList = res.addressList
        this.save_data.phone = res.userInfo.phone
        if(res.length==0){
          this.save_data.address=false
          return
        }
        // this.save_data.address=res[0]
      })
    },
    onClick(name,title){
      // if(name=='tab1'||name=='tab2'){
      //   this.onRefresh()
      // }
    },
    onLoadOld(){
      // 旧系统
      this.loading=true
      this.old_page+=1
      ajax.get('/Old/getYuYueList',{page:this.old_page,page_size:this.page_size}).then(res=>{
        this.loading=false
        if(parseInt(res.page)>=res.page_count){
          this.old_finished=true
        }
        res.list.map(item=>{
          this.old_list.push(item)
        })
      }).catch(e=>{
        console.log(e)
      })
    },
    onLoadNew(){
      //新系统
      this.loading=true
      this.page+=1
      ajax.getList({
        table:'BookOrder',
        scence:'mg_book_order_list',
        page:this.page,
        page_size:this.page.page_size,
        filter:this.filter
      }).then(res=>{
        this.loading=false
        if(parseInt(res.current_page)>=res.last_page){
          this.finished=true
        }
        res.data.map(item=>{
          this.list.push(item)
        })
      }).catch(e=>{
        console.log(e)
      })
    },
    onTypeConfirm(value, index) {
      this.save_data.type = value
      this.showType=false
    },
    onDateConfirm(value, index) {
      this.save_data.visit_time = this.formatDateTime(value)
      this.showDate=false
    },
    formatDateTime(inputTime) {
        var date = new Date(inputTime);
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        var d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        var h = date.getHours();
        h = h < 10 ? ('0' + h) : h;
        var minute = date.getMinutes();
        var second = date.getSeconds();
        minute = minute < 10 ? ('0' + minute) : minute;
        second = second < 10 ? ('0' + second) : second;
        return y + '-' + m + '-' + d+' '+h+':'+minute+':'+second;
    },
    formatter(type, value) {
      if (type === 'year') {
        return `${value}年`;
      } else if (type === 'month') {
        return `${value}月`
      } else if (type === 'day') {
        return `${value}日`
      }
      return value;
    },
    beforeRead(file){
      if(file.length>this.maxCount){
        this.$toast('最多上传'+this.maxCount+'张')
        return false
      }
      return true
    },
    afterRead(files) {
      if(files.length==undefined){
        files=[files]
      }
      let formData = new FormData()
      for(let f of files){
        formData.append('file_data', f.file)
        ajax.post('/Upload/toSave',formData).then(res=>{
            this.save_data.imgs.push({
              name:res.savename,
              url:res.path,
            })
        }).catch(e=>{
          console.log(e)
        })
      }
    },
    delImg(idx){
      this.save_data.imgs.splice(idx,1)
    },
    onSubmit(){
      util.cookies.remove('yuyueData')
      this.save_data.showTips=true
      ajax.post('/Book/create',this.save_data).then(res=>{
        this.save_data={
        type:[],
        user_weight:'',
        phone:this.addressNow.phone,
        visit_time:'',
        address:this.addressNow.address,
        area_code:this.addressNow.code,
        imgs:[],
        desc:''
      }
      this.files=[]
      this.activeName='tab2'
      this.onRefresh()
      }).catch(error=>{
        this.$toast(error.msg)
      })
    },
    onRefresh() {
      if(this.activeName=='tab2'){
        this.page=0
        this.list=[]
        this.finished=false
        this.onLoadNew()
      }else if(this.activeName=='tab3'){
        this.old_page=0
        this.old_list=[]
        this.old_finished=false
        this.onLoadOld()
      }
      this.isLoading = false
    },
  }
}
</script>
<style scoped>
.info{
  text-indent: 2em;
}
.flex
{
  display: flex !important;
  align-items: center;
  align-content: center;
}
.space-between
{
  justify-content: space-between !important;
  text-align: left !important;
}
</style>
